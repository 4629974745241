<template>
    <div class="my_order">
        <div class="basic_info">
            <div class="logo" @click="updataLogo">
                <img :src="logoUrl" alt="">
                <span>
                    <i class="el-icon-edit-outline"></i>
                </span>
            </div>
            <div class="user">
                <span>{{ mobile }}</span>
                <!-- <span>会员至：{{ authEndDate }}</span> -->
                <div>
                    <span>{{ isTest ? '会员至：' : '试用至：'}}</span>
                    <span :style="{ color: authVisible ? '#fff' : '#f00'}">{{ authEndDate }}</span>
                </div>
            </div>
            <span @click="payOrder">续费会员</span>
        </div>
        <div class="content_table">
            <h3>消费记录</h3>
            <el-table :data="dataSource" style="width: 100%" v-loading="loading">
                <el-table-column label="订单号" prop="orderId"></el-table-column>
                <el-table-column label="付费价格" prop="price" sortable>
                    <template slot-scope="scope">
                        <span>{{ scope.row.price / 100 }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="购买商品" prop="description"></el-table-column>
                <el-table-column label="消费时间" prop="createTime" sortable></el-table-column>
                <el-table-column label="购买状态" prop="status">
                    <template slot-scope="scope">
                        <span :style="{ color: scope.row.status === 2 ? '#17F210' : '#FF0000'}">{{ scope.row.status === 2 ? '正常' : '未支付' }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="我的发票" width="100px" align="right">
                    <template>
                        <span>查看票据</span>
                    </template>
                </el-table-column> -->
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, jumper, sizes"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
        <input type="file" ref="logoInput" style="display: none" @change="batchImport" accept=".jpeg,.jpg,.png,.gif">
        <CropperDialog ref="logoCropper" :fixed="true" :nameControl="false" :fixedNumber="[1,1]" :fixedControl="false" @receiveData="receiveData" />
    </div>
</template>
<script>
import { getCookie } from "@/utils/utils.js";
import CropperDialog from '@/components/cropperDialog'
import User from '../../../../assets/images/user.svg'
export default {
    data: function() {
        return {
            logoUrl: User,
            mobile: '***********',
            authEndDate: '',
            authVisible: true, // 权限没有到期
            isTest: 0, // 0|试用 1|会员
            dataSource: [],
            loading: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
        }
    },
    components: {
        CropperDialog
    },
    created() {
        try {
            let userAuthInfo = JSON.parse(localStorage.getItem('userAuthInfo'))
            if (userAuthInfo instanceof Object && (this.$moment(userAuthInfo.endDate).valueOf() > this.$moment().valueOf())) {
                this.authVisible = true
            } else {
                this.authVisible = false
            }
            this.isTest = userAuthInfo.isTest
            this.authEndDate = userAuthInfo.endDate
        } catch (error) {
            console.log(error)
        }
        this.getDataSource();
        this.updateLogoUrl()
    },
    mounted() {
        this.$bus.$on('updateOrderList', () => {
            this.updateDataSource()
        })
    },
    beforeDestroy() {
         this.$bus.$off('updateOrderList');
    },
    methods: {
        updateLogoUrl() {
            try {
                let user = JSON.parse(getCookie('user'))
                if (user.headUrl && user.headUrl !== 'null') {
                    this.logoUrl = user.headUrl
                }
                if (user.mobileNumber && user.mobileNumber !== 'null') {
                    let str = user.mobileNumber.slice(3,7)
                    this.mobile = user.mobileNumber.replace(new RegExp(str), '****')
                }
            } catch (error) {
                console.log(error)
            }
        },
        updataLogo() {
            this.$refs.logoInput.click()
        },
        batchImport(e) {
            const [file] = e.target.files;
            const checkSize = this.imgBeforeUpload(file)
            if (checkSize) {
                this.$refs.logoCropper.openDialog(file)
            }
             // 清空input的值
            this.$refs.logoInput.value = ''
        },
        //图片上传前，检查大小是否超出
        imgBeforeUpload(file) {
            const isFileSizeFit = file.size / 1024 / 1024 < 5;
            if (!isFileSizeFit) {
                this.$message.error("图片大小不能超过 5MB!");
            }

            return isFileSizeFit;
        },
        receiveData(value) {
            const { url } = value
            this.API.user.update({
                headUrl: url,
                userId: JSON.parse(getCookie('user')).userId,
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    // 成功后后台更新cookie中user信息
                    try {
                        this.$message.success('更新成功')
                        this.updateLogoUrl()
                        this.$bus.$emit('updateLogo')
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    this.$message.error('更新失败')
                }
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getDataSource();
        },
        // pageSize切换
        handleSizeChange(val) {
            this.pageNum = 1;
            this.pageSize = val;
            this.getDataSource();
        },
        getDataSource() {
            this.loading = true
            this.dataSource = []
            this.API.wxpay.getOutOrderInfoList({
                corpId: `dengyun_${JSON.parse(getCookie('user')).userId}`,
                userId: JSON.parse(getCookie('user')).userId,
                pageSize: this.pageSize,
                pageNum: this.pageNum
            }).then((res) => {
                this.loading = false
                if (res && res instanceof Object && res.data) {
                    this.dataSource = res.data.list
                    this.total = res.data.total
                }
            })
        },
        updateDataSource() {
            this.pageNum = 1
            this.getDataSource()
        },
        payOrder() {
            this.$bus.$emit('changePay', true)
        }
    },
}
</script>
<style lang="less" scoped>
.my_order {
    color: #fff;
    padding-bottom: 24px;
    .basic_info {
        display: flex;
        align-items: center;
        margin: 20px 30px;
        .logo {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;
            cursor: pointer;
            background: rgb(255, 177, 0);
            position: relative;
            > img {
                max-height: 100%;
                max-width: 100%;
            }
            &:hover {
                > span {
                    opacity: 1;
                }
            }
            > span {
                position: absolute;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(29, 29, 29, 0.7);
                opacity: 0;
                color: #fff;
                cursor: pointer;
                font-size: 1.375rem;
            }
        }
        .user {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 14px;
            height: 50px;
            > span:first-child {
                font-weight: bold;
                font-size: 20px;
            }
        }
        > span {
            background: linear-gradient(90deg, #F6E0AB 0%, #EABE72 100%);
            border-radius: 24px;
            padding: 8px 24px;
            color: #5B3A00;
            font-size: 24px;
            margin-left: 32px;
            cursor: pointer;
        }
    }
    .content_table {
        padding: 10px 24px;
        > h3 {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            font-size: 16px;
            height: 32px;
            line-height: 32px;
        }
        /deep/ .el-loading-mask {
            background-color: unset;
            // opacity: 0.5;
        }
        /deep/ .el-table {
            color: #fff;
        }
        /deep/ .el-table::before {
            height: 0;
        }
        /deep/ .el-table thead th {
            background: #16181A;
            color: rgba(255, 255, 255, 0.5);
            border: 0;
        }
        /deep/ .el-table tr {
            background: rgb(45, 47, 49);
            > td {
                border: 0;
            }
        }
        /deep/ .el-table tr:nth-child(2n) {
            background: #16181A;
        }
        /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
            background-color: #68696B;
        }
        /deep/ .el-table__empty-block {
            background-color: #68696B;
            .el-table__empty-text {
                color: #fff;
            }
        }
        .pagination {
            display: flex;
            justify-content: center;
            margin-top: 24px;
            /deep/ .el-pager li {
                margin: 0 5px;
                background-color: #f4f4f5;
                min-width: 30px;
                border-radius: 2px;
            }
            /deep/ .el-pager li.active {
                background-color: #4E8CFF;
                color: #fff;
            }
            /deep/ .el-pager li:hover {
                color: #4E8CFF;
            }
            /deep/ .el-pagination button:hover {
                color: #4E8CFF;
            }
            /deep/ .el-pagination .btn-prev {
                margin: 0 5px;
                background-color: #f4f4f5;
                min-width: 30px;
                border-radius: 2px;
                padding-right: 6px;
            }
            /deep/ .el-pagination .btn-next {
                margin: 0 5px;
                background-color: #f4f4f5;
                min-width: 30px;
                border-radius: 2px;
                padding-left: 6px;
            }
            /deep/ .el-pagination__total {
                color: #fff;
            }
            /deep/ .el-pagination__sizes {
                margin: 0;
            }
            /deep/ .el-pagination__jump {
                margin-left: 4px;
                color: #fff;
            }
        }
    }
    // .operation {
    //     span {
    //         cursor: pointer;
    //         &:hover {
    //             color: #FF9226;
    //         }
    //     }
    // }
}
</style>